<script setup lang="ts">
import {defu} from "defu";
import {useCmsTranslations} from "@shopware-pwa/composables-next";
import {mdiArrowRight} from "@mdi/js";
import type {Schemas} from "~/api-types/storeApiTypes";
import {toRefs} from "vue";
import {useRouter} from "vue-router";
import {useNotifications} from "#imports";
import {getProductFromPrice} from "@shopware-pwa/helpers-next";

const props = withDefaults(defineProps<{
  product: Schemas["Product"];
  compact?: boolean
}>(),{
  compact: false
})
const {product} = toRefs(props);


const {t} = useI18n()
const {addToCart} = useAddToCart(product);
const {isLoggedIn} = useUser();
const router = useRouter()
const {formatLink} = useInternationalization(useLocalePath());
const {pushSuccess, pushError} = useNotifications();

const loading = ref(false)

// let translations: Translations = {
//   product: {
//     investNow: "Jetzt investieren",
//   },
// };
//
// translations = defu(translations, useCmsTranslations()) as Translations;


async function addToCartProxy() {
  loading.value = true
  await addToCart();

  dataLayer.push(
      {
        event: 'shop_studio_google_tag_manager_cloud_add_to_cart',
        'google_analytics': {
          'event': 'add_to_cart',
          'value': product.value.calculatedPrice.totalPrice,
          'currency': 'EUR',
          'items': [
            {
              'google_business_vertical': 'retail',
              'id': product.value.productNumber,
              'name': product.value.name,
              'price': product.value.calculatedPrice.unitPrice,
              'currency': 'EUR',
              'quantity': product.value.calculatedPrice.quantity,
              'variant': ''
            }
          ]
        },
        'google_ads': {
          'event': 'conversion',
          'value': product.value.calculatedPrice.totalPrice,
          'currency': 'EUR'
        },
        'facebook': {
          'event': 'AddToCart',
          'value': product.value.calculatedPrice.totalPrice,
          'currency': 'EUR',
          'content_type': 'product',
          'content_ids': [product.value.productNumber],
          'contents': [
            {
              'id': product.value.productNumber,
              'quantity': product.value.calculatedPrice.quantity
            }
          ]
        },
        'pinterest': {
          'event': 'AddToCart',
          'line_items': [
            {
              'product_id': product.value.productNumber,
              'product_name': product.value.name,
              'product_price': product.value.calculatedPrice.unitPrice,
              'product_currency': 'EUR',
              'product_quantity': product.value.calculatedPrice.quantity,
              'product_variant': ''
            }
          ]
        },
        'data': {
          'total_price': product.value.calculatedPrice.totalPrice,
          'currency': 'EUR',
          'product_ids': [product.value.productNumber],
          'products': [
            {
              'id': product.value.id,
              'number': product.value.productNumber,
              'name': product.value.name,
              'price': product.value.calculatedPrice.unitPrice,
              'currency': 'EUR',
              'quantity': product.value.calculatedPrice.quantity,
              'variant': ''
            }
          ]
        }
      }
  )
  // await nextTick()
  // loading.value = false
  // pushSuccess(
  //     `${ props.product?.translated?.name } ${ translations.product.addedToCart }`,
  // );
  if (isLoggedIn.value) {
    router.push({path: "/checkout"});
  } else {
    router.push(formatLink("/register"));
  }

};
</script>

<template>
  <v-btn
      :append-icon="mdiArrowRight"
      data-testid="add-to-cart-button"
      type="button"
      variant="text"
      class="text-body-1"
      :loading="loading"
      @click="addToCartProxy"
  >
<!--    {{ translations.product.investNow }}-->
    {{ t('product.investNow') }}
  </v-btn>
</template>
